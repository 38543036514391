import React, {  useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Table, Modal } from "react-bootstrap";
import './calendario.css'

export default function Calendario(props) {
  const calendarElementRef = React.createRef();
  const { calendar } = props;
  const [dayInfo, setDayInfo] = useState({ show: false, date: "" });

  //Ordenamos los partes por fecha para establecer el rango del calendario
  const data = props.data.length
    ? props.data.sort((a, b) => {
        if (a.HoraInicio <= b.HoraInicio) {
          return -1;
        } else {
        }
        return 1;
      })
    : props.data;

  //Devuelve las horas totales de una fecha concreta.
  //DateString : yyyy-mm-dd
  const totalHorasDia = dateString => {
    //console.log(dateString)
    return data
      .reduce((acc, cur) => {
        if (cur.HoraInicio.slice(0, 10) === dateString) {
          return acc + cur.TiempoOperario;
        } else {
          return acc;
        }
      }, 0)
      .toFixed(1);
  };

  // Muestra la información detallada diaria
  const ShowDayInfo = () => {
    const infoClass = "alert alert-primary text-dark font-weight-bold";
    const horas = totalHorasDia(dayInfo.date);
    return (
      horas > 0 && (
        <Modal id="day-info-modal" show={dayInfo.show} onHide={() => setDayInfo({ ...dayInfo, show: false })}>
          <Modal.Header closeButton>
            <Modal.Title>{new Date(dayInfo.date).toLocaleDateString("es-ES",{day:"numeric",weekday:"long",month:"long"})}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered size="sm">
              <thead className="btn-dark font-weight-bold">
                <tr>
                  <td>OT</td>
                  <td>Descripción</td>
                  <td>Inicio</td>
                  <td>Fin</td>
                  <td className="text-right">Horas</td>
                </tr>
              </thead>
              <tbody>
                {data.map(item => {
                  if (item.HoraInicio.slice(0, 10) === dayInfo.date) {
                    return (
                      <tr key={item.IDMntoOTControl}>
                        <td className="px-1">{item.NROT.slice(5, 10)}</td>
                        <td className="px-1">{item.DescOT}</td>
                        <td className="px-1">{new Date(item.HoraInicio).toLocaleTimeString("es-ES", {hour:"numeric", minute:"2-digit"})}</td>
                        <td className="px-1">{new Date(item.HoraFin).toLocaleTimeString("es-ES", {hour:"numeric", minute:"2-digit"})}</td>
                        <td className="text-right px-0">{item.TiempoOperario.toFixed(1)}</td>
                      </tr>
                    );
                  }
                  return [];
                })}
              </tbody>
              <tfoot className={infoClass}>
                <tr>
                  <td colSpan={4}>TOTAL HORAS</td>
                  <td className="text-right"> {horas}</td>
                </tr>
              </tfoot>
            </Table>
          </Modal.Body>
        </Modal>
      )
    );
  };

  const handleCellContent = e => {
    //console.log(e)
    const dateStr = e.date.toISOString().slice(0, 10);
    const horas = totalHorasDia(dateStr);
    const dayNumber = e.el.querySelector(".fc-daygrid-day-number");
    //console.log(dayNumber)
    let calendarItem = calendar.data.find(item => item.fecha === dateStr);
    // Rellena a 0 las fechas del calendario que falten en googlesheet
    if(!calendarItem){
      // console.log('Sin horas', dateStr);
      calendarItem = {horas: 0, festivo: false}
    }
    // console.log(calendarItem.horas);
    // const divCalendar = `<div class = "btn-info text-center mb-2 p-1 rounded-circle" style="width: 36px; min-width: 36px; margin: auto">${calendarItem.horas}</div>`;
    const divRegistroClass = horas > 12 || horas < calendarItem.horas || horas % 0.5 !== 0 ? "bg-warning" : "bg-success text-light";
    // const divRegistro = `<div class = " ${divRegistroClass} text-center m-1 m-md-3 rounded ">${horas}</div>`;
    const divRegistro = `<div class = " ${divRegistroClass} info-horas ">${horas}</div>`;
    //Añade fondo gris al fin de semana.
    e.el.className = e.date.getDay() === 6 || e.date.getDay() === 0 ? "alert alert-secondary " : e.el.className;
    if (dayNumber && calendarItem.festivo) {
      dayNumber.className = "dia-festivo";
    }

    //Mostramos horas en estos casos
    if ((calendarItem.horas > 0 || horas > 0) && e.date.toISOString() <= new Date().toISOString()) {
      e.el.querySelector(".fc-daygrid-day-events").innerHTML = divRegistro;
      if (horas > 0) {
        e.el.style.cursor = "pointer";
      }
    }
  };

  //Devuelve un día más del último parte, para que se muestre en el calendario.
  // const fechafin = ()=>{
  //   let fecha = new Date(data[data.length-1].HoraInicio);
  //   fecha = new Date(fecha.getTime() + 24 * 60 * 60 * 1000);
  //   return fecha.toISOString();
  // }
  return data.length ? (
    <div key={data[0].IDOperario} className ="calendario">
      {/* Muestra el modal con la indo diaria */}
      {<ShowDayInfo/>}
      {/* <div id="leyenda" className="d-none d-lg-flex row mb-2"> */}
      <div id="leyenda" className="calendario-leyenda">
        {/* <div className=" mr-2 font-weight-bold">Leyenda</div> */}
        <div className=" text-center p-1  mr-2 bg-success text-light">Horas registradas</div>
        <div className=" text-center p-1  bg-warning">Revisar registro</div>
      </div>
      <FullCalendar
        //ref asigna el objeto FullCalendar a la constante para que podamos obtener la API
        //e interactuar con el objeto si nos hiciera falta.
        ref={calendarElementRef}
        timeZone="UTC"
        validRange={{
          //Fecha desde que hay registro en google sheet
          start: "2018-01-01",
          end: new Date().toISOString(),
        }}
        //Para mostrar botones propios (hay que visualizarlos en headerToolbar
        // customButtons = {{
        //   testButton : {
        //     text : 'Test',
        //     click : test
        //   }
        // }}
        headerToolbar={{
          left: "title",
          end: "prevYear prev,next nextYear",
          center: "today",
          // right: 'dayGridMonth,dayGridWeek'
        }}
        locale="esLocale"
        fixedWeekCount={false}
        // selectable
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        buttonText={{ today: "Hoy" }}
        firstDay={1} //Lunes primer día semanal
        dayCellDidMount={handleCellContent} //Añadimos las horas a la celda
        dateClick={e => setDayInfo({ show: true, date: e.dateStr })}
        //events = {partes}
        // eventContent = {handleEventContent}
        // eventDidMount = {e => e.el.classList = []}
        //Ponemos a 0 para que se muestre siempre "more"
        //dayMaxEvents = {0}
        //eventDisplay //Activa style para eventos
        // dayCellContent = {handleCellContent}
        //moreLinkDidMount = {(e)=>e.el.innerText='+ Info'} //Activa el enlace "+ more"
        //Creamos el array de eventos a renderizar
        //eventContent = {(e)=>{e.color =true;e.editable=true;}}
        //eventClick={function(e){setWeekends(!weekends)}}
        // eventMouseEnter = {(e)=>console.log(e)}
        // eventMouseLeave = {(e)=>console.log(e)}
        //select = {(e)=>console.log('click')}
        // dateClick = {function(cell){this.changeView('dayGridDay', cell.date)}}
      />
    </div>
  ) : (
    <p>No se han encontrado registros</p>
  );
}
