import React from 'react';


function LoadSpinner(){
 
  const divStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    width: '100vw',
    height: '100vh',
    'backgroundColor': 'rgba(0,0,0,.4)',
    'alignItems': 'center',
    'justifyContent': 'center',
    'zIndex': 1000,
    }
    const spStyle={
      height: '20vh',
      width: '20vh',
    }
    return(

      <div style = {divStyle}>
        <div className="spinner-border text-primary" style = {spStyle} ></div>
      </div>
    )
  }



export default LoadSpinner;
