import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database'
import 'firebase/storage';
import "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyAC7LB5Uo_5PmL62pvgiHg3WoOcZvMW-GA",
  authDomain: "herjai2020-17885.firebaseapp.com",
  databaseURL: "https://herjai2020-17885.firebaseio.com",
  projectId: "herjai2020-17885",
  storageBucket: "herjai2020-17885.appspot.com",
  messagingSenderId: "756673762930",
  appId: "1:756673762930:web:61dc3380ebbe31af4fd4eb",
  measurementId: "G-HFQPWHJZZ0"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  const remoteConfig = firebase.remoteConfig()
  const firestore = firebase.firestore();
  const auth = firebase.auth();
  const database = firebase.database();
  export {firestore, auth, database, remoteConfig};