// Biblioteca de ayuda para los Sheets de google.
const urlBase = 'https://script.google.com/macros/';
const googleUrls = {
  partes : `${urlBase}s/AKfycbwX0441Qm-0MJAk3FPA6M0XFCSjUOuF1D3Wu5FXI4Va7wJFFJsT4aMw/exec`,
}
//https://script.google.com/macros/s/AKfycbwX0441Qm-0MJAk3FPA6M0XFCSjUOuF1D3Wu5FXI4Va7wJFFJsT4aMw/exec


/*
SHEET PARTES
https://docs.google.com/spreadsheets/d/1wWcQOSskAaOT2hRWYWj2isBFpHppVzNOe-BofzJ00Ak/edit#gid=1158409354

*/

async function fecthGoogleSheet(sheetName){
  try{
    const response = await fetch(googleUrls.partes + (sheetName ? `?sheet=${sheetName}` : ''));
    return await response.json();

  }catch(error){
    console.log(error)
    throw new Error(error)
  }
}

async function getHoras2020(opr){
  // try{
  //   const response = await fetch(googleUrls.partes);
  //   const data = await response.json();
  //   let result = data.sort((a, b)=>{
  //     if(a.fecha > b.fecha){
  //       return -1;
  //     }
  //     return 1;
  //   });
  //   //Filtramos por operario
  //   result =  opr ? data.filter(item => item.cod_opr === opr) : data;
  //   //Filtramos por año
  //  //result = year ? result.filter(item => parseInt(item.fecha.slice(0,4)) >= year) : result;
  //   return result;
  // }catch{
  //   throw new Error('Error obteniendo horas acumuladas 2020')
  // }
}


async function getParteMensual(opr){
  try{
    const data = await fecthGoogleSheet('PARTES');
    return data.filter(item => item.herjai === opr)
      .sort((a, b) =>{
        if(a.cierre > b.cierre){
        return -1;
      }
      return 1;
    });
  }catch(error){
    console.log(error)
    throw new Error('Error obteniendo partes mensual')
  }
}

async function getLicencias(opr){
  try{
   
    return await fecthGoogleSheet('Licencias');
  }catch{
    throw new Error('Error obteniendo partes mensual')
  }
}

async function getDetalleLicencias(opr){
  try{
    const data = await fecthGoogleSheet('Detalle_Licencias');
    //console.log(data)
    return data.filter(item => item.idOPr === opr)
      .sort((a, b) =>{
        if(a.fInicio > b.fInicio){
        return -1;
      }
      return 1;
    });
  }catch{
    throw new Error('Error obteniendo partes mensual')
  }
}

async function getHorasAcum(opr){
  
  try{
    let data = await fecthGoogleSheet('Horas_Acum');
    if(opr) data = data.filter(item => item.idOpr === opr);
    // console.log(data)
    // console.log(data.filter(item => item.idOPr === opr))
    return data.sort((a, b) =>{
        if(a.fInicio > b.fInicio){
        return -1;
      }
      return 1;
    });
  }catch(error){
    console.log(error)
    throw new Error('Error obteniendo horas acumuladas')
  }
}


async function getVacaciones(opr){
  
  try{
    let data = await fecthGoogleSheet('Vacaciones');
    // console.log(data)
    // console.log(data.filter(item => item.idOPr === opr))
    if(opr) data = data.filter(item => item.idOpr === opr)
    return data.sort((a, b) =>{
        if(a.fInicio > b.fInicio){
        return -1;
      }
      return 1;
    });
  }catch(error){
    throw new Error('Error obteniendo vacaciones')
  }
}



export {
  getHoras2020,
  getParteMensual,
  getLicencias,
  getDetalleLicencias,
  getHorasAcum,
  getVacaciones,
}

