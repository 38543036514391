import "./router-app.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, NavLink, Switch, Route } from "react-router-dom";
import { auth } from "../../firebase";
import { getApiPartesOpr, getCalendarHerjai } from "../../modules/APIhelpers";
import Container from "react-bootstrap/Container";
import Calendario from "../calendario/Calendario";
import Notificaciones from "../Notificaciones";
import LoadSpinner from "../LoadSpinner";
// import Acum2020 from "./Acum2020";
import ParteMensual from "../parte-mensual/ParteMensual";
// import Licencias from "../Licencias";
import HorasAcum from "../horas-acum/HorasAcum";
import Vacaciones from "../vacaciones/Vacaciones";
import { getHorasAcum, getParteMensual, getVacaciones } from "../../modules/googleHelpers";
import { ArrowClockwise } from "react-bootstrap-icons";
import { Button, Col, Row } from "react-bootstrap";
import UserSelector from "../user-selector/UserSelector";

export default function RouterApp(props) {
  const { admin } = props;

  //Recogemos los partes del operario y el calendario her-jai;
  const [data, setData] = useState(false);
  const [calendar, setCalendar] = useState(false);
  const [user, setUser] = useState(auth.currentUser.displayName);

  // console.log('admin', admin, "data", data)

  useEffect(() => {
    //console.log("cargando datos en useEffect");
    if (!data) {
      getApiPartesOpr(user)
        .then(partes => {
          //console.log('Partes obtenidos', partes);
          //Añadimos los partes ordenados por fecha.
          const sortedPartes = partes.sort((a, b) => {
            if (a.HoraInicio < b.HoraInicio) return -1;
            if (a.HoraInicio > b.HoraInicio) return 1;
            return 0;
          });
          setData(sortedPartes);
        })
        .catch(err => {
          console.log("Error obteniendo partes");
          setData(null);
          //throw new Error(err);
        });
    }
    if (!calendar) {
      getCalendarHerjai()
        .then(calendar => setCalendar(calendar))
        .catch(error => {
          console.log("Error obteniendo el calendario", error);
          setCalendar(null);
        });
    }
  }, [user, data, calendar]);

  const UserTools = () => {
    if(!admin) return null;
    return(
    <div className="user-tools">
    <Button variant="success" size="sm" className="refresh-btn">

      <ArrowClockwise size={"1.5rem"}
        onClick={e => {
          e.preventDefault();
          //console.log(user)
          setData(false);
          setCalendar(false);
        }}
      ></ArrowClockwise>
    </Button>
        
          <UserSelector data={admin} activeUser={user} onConfirm={handleUserConfirm} />
       
      </div>
  )};

  const handleUserConfirm = selectedUser => {
    
    if (selectedUser && selectedUser.IDOperario !== user) {
      setData(false);
      setUser(selectedUser.IDOperario);
    }
  };

 
  // console.log("user", user);
  if (data === null || calendar === null) return <p style={{ marginTop: "75px" }}>Error cargando datos</p>;
  if (data === false || calendar === false) return <LoadSpinner />;
  return (
    <Container id="app-container" fluid>
     <UserTools/>

      <Router>
        <div className="router-links">
          {/* <NavLink to="/inicio" className="btn btn-dark" exact>
            Inicio
          </NavLink> */}

          <NavLink to="/calendario" className="btn btn-dark menu" exact>
            Calendario
          </NavLink>

          <NavLink to="/partemensual" className="btn btn-dark menu" exact>
            Parte Mensual
          </NavLink>

          <NavLink to="/horas" className="btn btn-dark menu" exact>
            Horas
          </NavLink>

          <NavLink to="/vacaciones" className="btn btn-dark menu" exact>
            Vacaciones
          </NavLink>

          <NavLink to="/notificaciones" className="btn btn-dark menu">
            Notificaciones
          </NavLink>

          {/*   <NavLink to="/detallelicencias" className="btn btn-warning text-white menu deprecated" exact>
            Detalle licencias
          </NavLink> */}
          {/* {admin && (
            <NavLink to="/admin" className="btn btn-dark menu" exact>
              Admin
            </NavLink>
          )} */}
          {/* <NavLink to="/2020" className="btn btn-dark menu" exact>
            2020
          </NavLink> */}

          {/* <NavLink to="/ayuda" className="btn btn-dark">
            Ayuda
          </NavLink> */}
        </div>
        <Switch>
          {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}

          <Route path="/partemensual">
            {/* <ParteMensual idOpr={parseInt(user)} /> */}
            <ParteMensual data={getParteMensual(Number(user))} />
          </Route>
          {/*  <Route path="/detallelicencias">
            <Licencias idOpr={parseInt(user)} />
          </Route> */}

          <Route path="/horas">
            {/* <HorasAcum  idOpr={parseInt(user)}/> */}
            <HorasAcum data={getHorasAcum(Number(user))} />
            {/* <HorasAcum data={Promise.resolve(data)} /> */}
          </Route>

          <Route path="/vacaciones">
            {/* <Vacaciones idOpr={parseInt(user)}/> */}
            <Vacaciones data={getVacaciones(Number(user))} />
          </Route>
          <Route path="/calendario">
            {/* {console.log(calendar)} */}
            {/* Le cambiamos key porque si no no se renderiza fullcalendar */}
            <Calendario key={user} data={data} calendar={calendar} />
          </Route>

          <Route path="/">
            <Notificaciones idOpr={user} />
          </Route>

          {
            // admin && (
            //   <Route path="/admin">
            //     {/* <AdminPage/> */}
            //     <AdminPage />
            //   </Route>
            // )
          }

          {/* <Route path="/registro_horario">
              <Parte />
          </Route>
          <Route path="/">
            <Ayuda />
          </Route> */}
        </Switch>
      </Router>
    </Container>
  );
}
// const Ayuda = () => {
//   return (
//     <Container fluid>
//       <h2>Aquí apareceran temas de ayuda de la aplicación</h2>
//       <p className="alert alert-info">
//         Ya puedes revisar tus partes pulsando en la pestaña <span className="font-weight-bold">Calendario</span> del menú.
//       </p>
//       <p>
//         {" "}
//         <span className="font-weight-bold"> Debés</span> registrar las incidencias en la pestaña{" "}
//         <span className="alert-info font-weight-bold">Incidencias</span>.
//       </p>
//       <p>Puedes registrar cualquier tipo de incidencia, aunque algunas de ellas están predefinidas.</p>
//       <p>Siempre que sea posible, indica el nº de OT para poder identificar la obra y una pequeña descripción de la incidencia.</p>
//       <p>
//         Si quieres registrar alguna para hacer pruebas, indícalo en la descripción anotando la palabra <span className="font-weight-bold">PRUEBA</span> .
//       </p>
//       <div className="alert alert-danger text-center">POR MOTIVOS DE SEGURIDAD, ES PREFERIBLE DESCONECTARSE ANTES DE CERRAR EL NAVEGADOR </div>
//     </Container>
//   );
// };
