import "./user-selector.css";
import { useState } from "react";
import { Button, Col, ListGroup, Modal, Row } from "react-bootstrap";
import { List } from "react-bootstrap-icons";

export default function UserSelector({ data = [], activeUser, onConfirm }) {
  console.log("activeuser", activeUser);
  const current = data.find(operario => operario?.IDOperario === activeUser) || "";


  if (!current) throw new Error("Revise las propiedades enviadas al componente. No se ha encontrado el usuario activo");

  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});

  // const orderedData = data.filter(({ Baja, IDOperario }) => !Baja && IDOperario !== activeUser).concat(data.filter(operario => operario.Baja));
  const orderedData = [...data.filter(({ Baja, IDOperario }) => !Baja && IDOperario !== activeUser), ...data.filter(operario => operario.Baja)];
  const getOperarioStr = operario => `${operario.IDOperario} - ${operario.Nombre} ${operario.Apellidos}`;

  const Operario = ({ data, ...rest }) => (
    <Row {...rest} className="operario-row" >
      <Col xs={1}>{data.IDOperario}</Col>
      <Col>{`${data.Nombre} ${data.Apellidos}`}</Col>
    </Row>
  );



  const handleShow = () => {
    setShow(true);
  };

  const handleSelect = selected => {
    setSelected(orderedData.find(operario => operario.IDOperario === selected));
  };

  const handleConfirm = () => {
    onConfirm(selected);
    setShow(false);
  };

  const handleCancel = () => {
    setSelected({});
    setShow(false);
  };

  const getListItemVariant= operario=>{
    if(operario.IDOperario === selected.IDOperario) return "primary";
    if(operario.Baja) return "secondary"
  }

  return (
    <>
      <Button as="div" variant="light" onClick={handleShow} className="selector-btn">
        <div>
          <List />
          {getOperarioStr(current)}
        </div>
      </Button>
      <Modal show={show} backdrop="static" keyboard={false} scrollable>
        <Modal.Header>
          <Modal.Title>Lista de operarios</Modal.Title>
        </Modal.Header>
        <Row  className="alert alert-success">
          
          <Col xs={2}>(ACTUAL)</Col>
          <Col>{`${current.IDOperario} ${current.Nombre} ${current.Apellidos}`}</Col>
          </Row>
   

        <Modal.Body>
          <ListGroup variant="flush">
            {orderedData.map(operario => (
              <ListGroup.Item key={operario.IDOperario} variant={getListItemVariant(operario)}>
                <Operario data={operario} onClick={() => handleSelect(operario.IDOperario)} />
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button variant="primary"  disabled={!selected} onClick={handleConfirm}>
            Seleccionar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
