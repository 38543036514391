import React from "react";
import { useState } from "react";
import {v4 as uuid} from 'uuid'


// import { getHorasAcum } from "../../modules/googleHelpers";
import LoadSpinner from "../LoadSpinner";
import "./horas-acum.css";

const sumaHoras = (horasArr) => {
  return horasArr.reduce((prev, cur) => prev + cur, 0);
};

export default function HorasAcum({data}) {
  //Sería conveniente cambiar la data de useState a un hook en el Router

  const [horas, setHoras] = useState(null);
  const [filter, setFilter] = useState(1);

  data.then(items => setHoras(items))
  /* useEffect(() => {
    getHorasAcum(74).then((data) => setHoras(data));
  },[]); 
 */
  const getFilteredData = () => {
   
    if (filter === 2) return horas.filter((item) => item.factor > 0 );
    if (filter === 3) return horas.filter((item) => item.factor < 0);
    return horas;
  };

  if (!horas) {
    return <LoadSpinner />;
  } else {
    //  console.log('pintando con filtro = ', filter, getFilteredData())
    const totalHoras = parseFloat(
      sumaHoras(getFilteredData().map(({ total }) => total))
    );

    const Filtros = props => {
      return (
        <div {...props}> 
        
          <div className={filter === 1 && 'checked' || ''} onClick={() => setFilter(1)}>Todo</div>
          <div className={filter === 2 && 'checked'  || ''} onClick={() => setFilter(2)}>Acumulado</div>
          <div className={filter === 3 && 'checked'  || ''} onClick={() => setFilter(3)}>Descontado</div>
        </div>
      );
    };
  
    return (
      <div className="horas-acum">
         
        <p className="filtros-title">Pulsa para filtrar</p>
        
          <Filtros className = 'info' />
          

        <div className="horas-acum-data">
        <p className={`${totalHoras > 0 ? "alert-info" : "alert-danger"} horas-acum-total`}>
            TOTAL: {totalHoras.toFixed(1)}
            {filter !== 1 && " (Filtrado)"}{" "}
        </p>
        <table className="table table-striped  table-sm">
          <thead>
            <tr>
              <th className="td-date">Fecha</th>
              <th className="td-number">Cant.</th>
              <th className="td-number">Factor</th>
              <th className="td-number">Total</th>
              <th>Observaciones</th>
            </tr>
          </thead>
          <tbody>
            {getFilteredData().map(
              ({ fInicio, cantidad, factor, total, observaciones }) => (
                <tr
                  key = {uuid()}
                  style={
                    total > 0
                      ? { color: "green" }
                      : total < 0
                      ? { color: "red" }
                      : {}
                  }
                >
                  <td className="td-date">
                    {new Date(fInicio).toLocaleDateString(undefined, {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td className="td-number">{cantidad}</td>
                  <td className="td-number">{factor}</td>
                  <td className="td-number total">{total}</td>
                  <td>{observaciones}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
        </div>
      </div>
    );
  }
}
