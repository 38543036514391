import React, { useState } from "react";
import { Container, Form, Button, FormGroup, Alert, InputGroup, FormControl } from "react-bootstrap";
//import { useRouteMatch } from 'react-router-dom';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai'
import { auth } from "../firebase";

function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState('password')
  const [passwordReset, setPasswordReset] = useState(false);
  const [alertConfig, setAlertConfig] = useState({ show: false });
  const patronEmail =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  //Recogemos todos los usuarios de Expertis para hacer el login por código (enviando email)
  // useEffect(()=>{
  //    getApiOperarios()
  //   .then(usuariosAPI=>{
  //     //Filtramos usuarios de alta y con departamento.
  //     setUsers(usuariosAPI.filter(usuario=>!usuario.Baja && usuario.IDDepartamento));
  //     });

  // },[]);

  // useEffect(()=>{
  //   if (users){
  //    setUser(users.find(item=>item.IDOperario === idOpr));
  //   }
  // }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwordReset) {
      auth
        .sendPasswordResetEmail(email)
        .then(() => {
          setAlertConfig({
            ...alertConfig,
            show: true,
            class: "success",
            text: `Se ha enviado a tu correo una enlace para reestablecer la contraseña.
            Si no lo encuentras, no olvides revisar el correo no deseado.`,
          });
        })
        .catch((err) => {
          setAlertConfig({
            ...alertConfig,
            show: true,
            class: "danger",
            text: `Se ha producido un error al  enviar el enlace.
            Compueba que el email introducido es el que has facilitado a la empresa.`,
          });
        })
        .finally(() => {
          setPasswordReset(false);
        });
    } else {
      //console.log(this.state);
      auth.setPersistence("session").then(() => {
        //Seleccionamos persistencia a "cierre de pestaña" session
        auth
          .signInWithEmailAndPassword(email.trim(), password.trim())
          .catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
            console.log(errorCode, errorMessage);
            //Hay que pintar el error en el formulario
            //alert('El usuario y/o la contraseña son incorrectos')
            setAlertConfig({
              ...alertConfig,
              show: true,
              class: "danger",
              text: "Usuario y/o contraseña incorrectos",
            });
          });
      });
    }
    setEmail("");
    setPassword("");
  };

  // Utilizamos validate para habilitar botón submit
  const validate = () => {
    // const patron = /^\d{3}$/; ****Patrón 3 dígitos***
    return patronEmail.test(email) && (password.length > 0 || passwordReset);
  };

  return (
    <Container 
      fluid
      className="d-flex justify-content-center"
      style={{ marginTop: "100px" }}
    >
      <Form style={{ width: "300px" }}>
        <FormGroup>
          <Form.Label> Usuario</Form.Label>
          <Form.Control
            id="email"
            className={
              email.length && !patronEmail.test(email)
                ? "alert alert-danger"
                : ""
            }
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="text"
            placeholder="Email"
            value={email}
          />
        </FormGroup>
        {/* A mostrar sólo para resetear contraseña */}
        {!passwordReset && (
          
        <InputGroup className="mb-3">
          <FormControl
            onChange={(e) => setPassword(e.target.value)}
            aria-label="Contraseña"
            placeholder="Contraseña"
            type= {passwordType}
            value={password}
          />
          <InputGroup.Text 
          className = "password-input"
          onClick = {()=> {
           if (passwordType === 'password') {
             setPasswordType('text')
             return;
            }
            setPasswordType('password')
          }}
          >
            {passwordType === 'password' ? <AiOutlineEye size = "1.2rem"/> : <AiOutlineEyeInvisible size = "1.2rem"/> }
          </InputGroup.Text>
        </InputGroup>
        )}
        <a
          id="reset"
          href="#reset"
          onClick={() => {
            setAlertConfig({ ...alertConfig, show: false });
            setPasswordReset(!passwordReset);
          }}
          className="text-decoration-none btn-link d-block"
        >
          {passwordReset ? "Volver a login" : "No recuerdo la contraseña"}
        </a>
          
        <Button
          onClick={handleSubmit}
          disabled={!validate()}
          variant={passwordReset ? "warning mt-3 mb-1" : "primary mt-3 mb-1"}
          type="submit"
        >
          {passwordReset ? "Restablecer contraseña" : "Acceder"}
        </Button>
        <Alert
          variant={alertConfig.class}
          onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          dismissible
          show={alertConfig.show}
        >
          {alertConfig.text}
        </Alert>
      </Form>
    </Container>
  );
}

export default Login;
