import React, { useState, useEffect } from "react";
//import {getParteMensual} from '../../modules/googleHelpers'
import { Card, Table } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import LoadSpinner from "../LoadSpinner";
import "./parte-mensual.css";
import { forceNumber } from "../helpers";

export default function ParteMensual({ data }) {
  const [partes, setPartes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    data
      .then(items => setPartes(items))
      .catch(() => setError(error))
      .finally(() => setLoading(false)); //Aquí podríamos gestionar el error de carga
  }, [data]);

  const getYearHistoric = partes => {
    const mapedPartes = partes.map(({ cierre }) => {
      const year = new Date(cierre).getFullYear();
      return isNaN(year) ? null : year;
    });
    return Array.from(new Set(mapedPartes));
  };

  if (loading) return <LoadSpinner />;
  if (error) return <p>Se ha producido un error accediendo a los partes</p>;
  const yearHistoric = getYearHistoric(partes);
  return (
    <div className="parte-mensual">
      <PartesResumen partes={partes} />
      {yearHistoric.map(year => (
        <Accordion defaultActiveKey={Math.max(...yearHistoric)} key={year}>
          <Card>
            <Accordion.Toggle as={Card.Header} className="text-info" eventKey={year}>
              {year}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={year}>
              <Card.Body>
                <PartesList key={year} partes={partes.filter(({ cierre }) => new Date(cierre).getFullYear() === year)} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ))}
    </div>
  );
}

const PartesList = ({ partes }) => {
  const showKilometers = partes.find(({km})=>typeof km==="number");
  // console.log(partes)
  return (
    <Table size="sm" id="data-table" striped>
      <thead>
        <tr>
          <th></th>
          <th colSpan={2}>Horas Normales</th>
          <th colSpan={2}>Horas Festivas</th>
        

        </tr>
        <tr>
          <th>Cierre</th>
          <th >Tot</th>
          <th >Acum</th>
          <th >Tot</th>
          <th >Acum</th>
          <th>Recup</th>
          {showKilometers && <th >km</th> }
        </tr>
      </thead>
      <tbody>

      {partes.map(parte => {
       
        return <ParteDetail parte={parte}  key={parte.cierre}/>;
      })}
      </tbody>
    </Table>
  );
};

const ParteDetail = ({ parte, id }) => {
  const formatter = new Intl.DateTimeFormat("es", { day: "2-digit", month: "2-digit", year: "numeric" });

  const date = new Date(parte.cierre);
  const formatedDate = formatter.format(date);
  return (
    <tr className="data-row">
      <td >{formatedDate}</td>
      <td className="normales">{parte.horas_normal || ""}</td>
      <td className="normales">{parte.acum_normal|| ""}</td>
      <td className="festivas">{parte.horas_fest|| ""}</td>
      <td className="festivas">{parte.acum_fest|| ""}</td>
      <td className="recuperar">{parte.recuperar|| ""}</td>
      <td>{parte.km.toLocaleString("es-AR")}</td>
      
    </tr>
  );
};

// const ParteDetail = ({ parte, id }) => {
//   return (
//     <Card>
//       <Accordion.Toggle as={Card.Header} className="text-info" eventKey={id.toString()}>
//         Parte mensual cerrado el {new Date(parte.cierre).toLocaleDateString()}
//       </Accordion.Toggle>
//       <Accordion.Collapse eventKey={id.toString()}>
//         <Card.Body>
//           {/* {forceNumber(parte.acum_2020) ? <p>- Recuperas: {parte.acum_2020} horas de 2020 </p> : null} */}
//           <p>
//             - Horas normales: {parte.horas_normal}.{parte.acum_normal ? ` (Acumulas ${parte.acum_normal})` : " (No acumulas ninguna)"}{" "}
//           </p>
//           <p>
//             - Horas festivas: {parte.horas_fest}.{parte.acum_fest ? ` (Acumulas ${parte.acum_fest})` : " (No acumulas ninguna)"}{" "}
//           </p>
//           {parte.recuperar && <p>{`- Recuperas: ${parte.recuperar} horas`}</p>}
//           {parte.km && <p>{`- Kilometros: ${parte.km.toLocaleString("es-AR")}`}</p>}
//           {forceNumber(parte.total_reten) ? <p>- Días de retén: {parte.total_reten}.</p> : null}
//         </Card.Body>
//       </Accordion.Collapse>
//     </Card>
//   );
// };

// const PartesList = ({ partes }) => (

//     partes.map((parte, idx) => (
//       <ParteDetail parte={parte} id={idx} key={crypto.randomUUID()} />
//     ))

// );

// const ParteDetail = ({ parte, id }) => {
//   return (
//     <Card>
//       <Accordion.Toggle as={Card.Header} className="text-info" eventKey={id.toString()}>
//         Parte mensual cerrado el {new Date(parte.cierre).toLocaleDateString()}
//       </Accordion.Toggle>
//       <Accordion.Collapse eventKey={id.toString()}>
//         <Card.Body>
//           {/* {forceNumber(parte.acum_2020) ? <p>- Recuperas: {parte.acum_2020} horas de 2020 </p> : null} */}
//           <p>
//             - Horas normales: {parte.horas_normal}.{parte.acum_normal ? ` (Acumulas ${parte.acum_normal})` : " (No acumulas ninguna)"}{" "}
//           </p>
//           <p>
//             - Horas festivas: {parte.horas_fest}.{parte.acum_fest ? ` (Acumulas ${parte.acum_fest})` : " (No acumulas ninguna)"}{" "}
//           </p>
//           {parte.recuperar && <p>{`- Recuperas: ${parte.recuperar} horas`}</p>}
//           {parte.km && <p>{`- Kilometros: ${parte.km.toLocaleString("es-AR")}`}</p>}
//           {forceNumber(parte.total_reten) ? <p>- Días de retén: {parte.total_reten}.</p> : null}
//         </Card.Body>
//       </Accordion.Collapse>
//     </Card>
//   );
// };

function PartesResumen({ partes }) {
  /**
   * Devuelve la suma de un campo numérico de un array de objetos
   * @param {*} array
   * @param {*} propToAcum
   * @returns {Number}
   */
  const acumulador = (array, propToAcum) => {
    return array.reduce((acum, cur) => {
      return acum + forceNumber(cur[propToAcum]);
    }, 0);
  };
  const horasNormal = acumulador(partes, "horas_normal");
  // console.log(horasNormal)
  //const horasNormalAcum = acumulador(partes, 'acum_normal');
  const horasFest = acumulador(partes, "horas_fest");
  //const horasFestAcum = acumulador(partes, 'acum_fest');

  return (
    <div>
      <Table striped size="sm" style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <td colSpan={2} className="text-center font-weight-bold">
              TOTAL HORAS REALIZADAS
            </td>
          </tr>
          <tr>
            <td>Normales</td>
            <td>Festivas</td>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-info text-light font-weight-bold">
            <td>{horasNormal}</td>
            <td>{horasFest}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
