// Devuelve date con fecha y hora de OT Expertis

function dateFromOt(strDate, strHora){

  const year = strDate.split('/')[2],
  month = strDate.split('/')[1]-1,
  day = strDate.split('/')[0],
  hour = strHora.split(':')[0],
  minutes = strHora.split(':')[1];

  return new Date (year, month, day, hour, minutes);
}

//Convierte una cadena (dd/mm/aa) a fecha (number)

function dateStrToNumber(str){
  new Date (str.split('/')[2], str.split('/')[1]-1,str.split('/')[0]).getTime();
}

//Convierte una fecha (date) a cadena (dd/mm/aaaa)

function dateToStr(date){
  const dia = date.getDate()<10 ? `0${date.getDate()}` : date.getDate();
  const mes = date.getMonth()< 8 ? `0${date.getMonth()+1}` : date.getMonth()+1; 
  return `${dia}/${mes}/${date.getFullYear()}`;
}

//Convierte  un valor en minutos a una cadena de hora (hh:mm) 

function minutesToStr(value){
  const horas =Math.floor(value/60);
  const minutos = value % 60;
  let str = horas<10 ? `0${horas}` : horas.toString();
  str+=':';
  str += minutos<10 ? `0${minutos}` :minutos.toString();
  //console.log(str);
  return str;
}

//Convierte una cadena (hh:mm) a minutos (number). 

function strToMinutes(str){
  return parseInt(str.slice(0,2)*60) + parseInt(str.slice(3))
}

/**
 * Convierte cualquier argumento a number
 * @param {*} arg 
 * @returns 
 */
const forceNumber = arg => !arg || isNaN(arg) ? 0: (arg);

export {
  dateFromOt,
  dateStrToNumber,
  dateToStr,
  minutesToStr,
  strToMinutes,
  forceNumber
}



 
 