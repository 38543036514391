import "./vacaciones.css";
import React, { useState } from "react";
// import { getVacaciones } from "../../modules/googleHelpers";
import LoadSpinner from "../LoadSpinner";
import { Accordion, Card } from "react-bootstrap";

export default function Vacaciones({ data }) {
  const [vacaciones, setVacaciones] = useState(null);

  data
    .then((items) => setVacaciones(items))
    .catch((error) => {
      console.error(error);
      setVacaciones(error.message);
    });

  /*   useEffect(() => {
    console.log('effect')
    getVacaciones(idOpr)
      .then((data) => setVacaciones(data))
      .catch((error) => {
        console.error(error);
        setVacaciones(error.message);
      });
  }, [idOpr]); */

  const vacacionesTipo = () =>
    vacaciones
      .reduce((acum, { descLicencia }) => {
        if (!acum.includes(descLicencia)) acum.push(descLicencia);
        return acum;
      }, [])
      .sort((a, b) => {
        if (a < b) return 1;
        return -1;
      });

  if (vacaciones === null) return <LoadSpinner />;
  if (typeof vacaciones == "string") return <p>Error cargando datos</p>;
  return (
    <div>
      {vacacionesTipo().map((tipo) => (
        <VacacionesAccordion
          data={vacaciones.filter(({ descLicencia }) => descLicencia === tipo)}
          tipo={tipo}
          key={tipo}
        />
        // <VacacionesLista
        //   data={vacaciones.filter(({ descLicencia }) => descLicencia === tipo)}
        //   tipo={tipo}
        //   key={tipo}
        // />
      ))}
    </div>
  );
}

const VacacionesAccordion = ({ data = [], tipo }) => {
  const vacacionesPendientes = data.reduce(
    (acum, { total }) => acum + total,
    0
  );
  return (
    <Accordion>
      <Card>
        <Accordion.Toggle as={Card.Header} className="text-info" eventKey={1}>
          <span
            className={`vacaciones-container-title ${
              !vacacionesPendientes ? "vacaciones-consumidas" : ""
            }`}
          >
            {tipo.toUpperCase()}
          </span>
          <span className={`vacaciones-title `}>
            {vacacionesPendientes
              ? `${vacacionesPendientes} días pendientes`
              : ""}
          </span>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={1}>
          <Card.Body >
            <table className="table table-striped  table-sm">
              <tbody>
                {data.map(
                  (
                    { or, fInicio, fFin, descLicencia, total, observaciones },
                    idx
                  ) => {
                    // console.log(fInicio, total)
                    return (
                      <tr key={"" + idx + or}>
                        <td>
                          {fInicio &&
                            new Date(fInicio).toLocaleDateString(undefined, {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                        </td>
                        <td>
                          {fFin &&
                            new Date(fFin).toLocaleDateString(undefined, {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                        </td>
                        <td>{total}</td>
                        <td>{observaciones}</td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

const VacacionesLista = ({ data, tipo }) => {
  const vacacionesPendientes = data.reduce(
    (acum, { total }) => acum + total,
    0
  );
  return (
    <div className="vacaciones-container">
      <span className="vacaciones-container-title ">{tipo.toUpperCase()}</span>
      <span
        className={`vacaciones-title ${
          !vacacionesPendientes ? "vacaciones-consumidas" : ""
        }`}
      >
        {vacacionesPendientes
          ? `${vacacionesPendientes} días pendientes`
          : "Consumidas"}
      </span>
      <table className="table table-striped  table-sm">
        <tbody>
          {data.map(
            (
              { or, fInicio, fFin, descLicencia, total, observaciones },
              idx
            ) => {
              // console.log(fInicio, total)
              return (
                <tr key={"" + idx + or}>
                  <td>
                    {fInicio &&
                      new Date(fInicio).toLocaleDateString(undefined, {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                  </td>
                  <td>
                    {fFin &&
                      new Date(fFin).toLocaleDateString(undefined, {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                  </td>
                  <td>{total}</td>
                  <td>{observaciones}</td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};


