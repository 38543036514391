import { remoteConfig } from "../firebase";

async function fetchAPI(options){
  //console.log(options)
  const {query, body} = options;
  let headers = options.headers || {};
  //Convertimos en promesas las peticiones de API de red y externa.
  //Solicitamos las dos y nos quedamos con la que funcione o error.
  //No se recogen errores ya que se pueden gestionar en la promise devuelta.
  

  const fetchToPromise = async (url)=>{
    headers["Content-Type"] = "application/x-www-form-urlencoded";
    const params = {method: 'POST', body, headers};
    const response =  await fetch(url, params);
    const data = await response.json();
    return data;
  }

  const urlExt = `https://api.her-jai.com/${query}`;
  return await fetchToPromise(urlExt);
  
}
//*********************************************************************************************************************





// const baseUrl = 'http://192.168.24.204:8083/';
// const baseUrl = 'http://herjai.dnsalias.com:8083/';
// const baseUrl = 'https://api.her-jai.com/';
// const baseUrl = 'https://api-expertis.herokuapp.com/';


/* SHEET HELPER CALENDARIO

    https://docs.google.com/spreadsheets/d/1JFoCVTnCSBnWFpC28cvR60JaO5gtKS0veG8S-fjom1k/edit#gid=1893363052

*/
//**************************** QUITAR URL RED http EN PRODUCCIÓN **************************************

// const urlArr = ['https://api-expertis-2022.oa.r.appspot.com/', 'https://api.her-jai.com/'];
const urlArr = ['https://api.her-jai.com/'];  //Eliminamos la url que teníamos de apoyo porque era una redirección sin más.
const baseUrlGoogleSheet = 'https://script.google.com/macros/s/AKfycbzAEOrJD_5MB0Ct47EbYF87q4KkkN61en2zkr0fM0mdcD7qIm_3-_0Y/exec';
const options= {
  method: 'POST',
  headers:{"Content-Type": "application/x-www-form-urlencoded"}
  }
  let token = {};
  async function getApiToken(){
    if(!token.access_token){
    await remoteConfig.fetchAndActivate();
    const username = remoteConfig.getString("username");
    const password = remoteConfig.getString("password");
    const body =  `username=${username}&password=${password}&grant_type=password`;
  const reqOptions= {
    ...options,
    body 
  }
  const promises = urlArr.map(baseUrl => fetch(`${baseUrl}ObtenerToken`,reqOptions));
  const response = await Promise.any(promises);
  //console.log(response)
  token = await response.json();
  console.log('Token de acceso generado');
  }  
  return token.access_token;
}

//Devuelve objeto operario o array de objetos si !idOperario
async function getApiOperarios(idOperario){
  const token = await getApiToken();
  //console.log('token:', token)
  const reqOptions= {...options};
  reqOptions.headers.Authorization = `Bearer ${token}`
  const promises = urlArr.map(baseUrl => fetch(`${baseUrl}API/Operarios/`+ (idOperario || ''),reqOptions))
  const response = await Promise.any(promises);
  const data = await response.json();
  //Filtramos operarios de alta y no "externos".
  //console.log(data);
  return idOperario ? data.find(operario => operario.IDOperario === idOperario) : data;
}

//Devuelve todos los partes de operarios o los de uno concreto.
async function getApiPartesOpr(idOperario){
  const token = await getApiToken();
  const reqOptions= {...options};
  reqOptions.headers.Authorization = `Bearer ${token}`;
  const promises = urlArr.map(baseUrl => fetch(`${baseUrl}API/Partes/`+ (idOperario ?  `?IDOperario=${idOperario}` : ''), reqOptions));
  const response = await Promise.any(promises);
  return await response.json();
}

async function getApiPartesOT(idOt = 0){
  const token = await getApiToken();
  const reqOptions= {...options};
  reqOptions.headers.Authorization = `Bearer ${token}`;
  const promises = urlArr.map(baseUrl => fetch(`${baseUrl}API/Partes/?IDOT=${idOt}`, reqOptions));
  const response = await Promise.any(promises);
  return await response.json();
}

async function getCalendarHerjai(){
  const res = await fetch(`${baseUrlGoogleSheet}?solicitud=calendario`);
  //console.log(await res.json())
  return await res.json();
  
}

// function getApiError(){
//   return new Promise((resolve, reject)=>reject('Error generado desde ApiHelpers'))
// }

export {
  fetchAPI,
  getApiOperarios,
  getApiPartesOpr,
  getApiPartesOT,
  getCalendarHerjai,
  //getApiError
}

