import React, { useState, useEffect } from "react";
import { Collapse, Table, Container, Form, Button, ListGroup, Alert } from "react-bootstrap";
import { firestore, auth } from "../firebase";
import { v4 as uuid } from "uuid";

export default function Notificaciones(props) {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(true);
  //console.log(props)
  return (
    <Container fluid>
      {show &&
      <Alert variant="info" className="mt-3" onClose={()=>setShow(false)} dismissible>
        Recuerda que por cada notificación que genere la primera incidencia del mes, cada trabajador que se encuentre en la obra acumulará una hora.

      </Alert>}
      <Button className ="my-2" hidden={open} variant="warning" onClick={() => setOpen(!open)} aria-controls="nueva_notificacion" aria-expanded={open}>
        Nueva notificación
      </Button>
      <Collapse in={open} className="pb-2 mb-5">
        <div id="nueva_incidencia">
          <NotificacionForm cancel={() => setOpen(false)} />
        </div>
      </Collapse>
      {!open && (
        <>
          <NotificacionesLista idOpr={props.idOpr} />
        </>
      )}
    </Container>
  );
}

function NotificacionesLista(props) {
  const { idOpr } = props;
  const [notificaciones, setNotificaciones] = useState();
  useEffect(() => {
    firestore
      .collection("notificaciones")
      .where("solicitante", "array-contains", idOpr)
      .get()
      .then(query => {
        //console.log(query)
        setNotificaciones(query);
      });
  }, [idOpr]);
  return (
    <>
      {notificaciones && notificaciones.docs.length ? 
        <>
          <h2>Comunicaciones registradas</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Notificación</th>
                <th>Ref. Obra</th>
                <th>Estado</th>
                <th>Fecha</th>
                <th>Incidencia</th>
              </tr>
            </thead>
            <tbody>
              {notificaciones.docs.map((item, idx) => {
                const doc = item.data();
                const fecha = new Date(doc.fecha.seconds * 1000).toLocaleString(); // Firestore almacena segundos y nanosegunos.
                return (
                  <tr key={uuid()} className={doc.estado === "Tramitada" ? "text-success" : ""}>
                    <td>{idx}</td>
                    <td>{doc.notificacion}</td>
                    <td>{doc.ot}</td>
                    <td>{doc.estado}</td>
                    <td>{fecha}</td>
                    <td>{doc.incidencia}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
       : 
        <h5>No se han encontrado notificaciones registradas</h5>
      }
    </>
  );
}

/*
  Hay que enviar el handler desde la función principal para que se repinte al registrar nueva incidencia.
  De momento funciona con reload
  */

export function NotificacionForm(props) {
  const tipos = [
    "No se dispone del material necesario para realizar el trabajo",
    "No se dispone de la herramienta adecuada para realizar el trabajo",
    "No se localizan equipos que deberían estar en el vehículo",
    "Se ha estropeado una máquina, herramienta o vehículo",
    "No se puede realizar el trabajo porque las indicaciones no se han dado o no se han entendido correctamente",
    "No se puede acceder a obra por algún motivo: es privado, falta documentación...",
    "El trabajo indicado ya estaba realizado",
    "Otro tipo de notificación",
  ];
  const [notificacion, setNotificacion] = useState();
  const [ot, setOt] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const envioNotificacion = async () => {
    const fecha = new Date();
    const {displayName} = auth.currentUser;
    const docId= `${fecha.toISOString().slice(0,10)}_${displayName}_${crypto.randomUUID()}`;
    await firestore
      .collection("notificaciones")
      .doc(docId)
      .set({
        solicitante: [displayName],
        fecha,
        ot,
        notificacion,
        descripcion,
        estado: "Solicitada",
       
      })
      .then(() => {
        setNotificacion(undefined);
        alert("Se ha registrado la notificación");
      })
      .catch(err => alert(`Error enviando la notificación: ${err}`));
    window.location.reload();
  };
  return (
    <Container fluid>
      <Form>
        <Form.Group className="mb-0" controlId="ot">
          <Form.Label className="font-weight-bold">Introduce el número de OT o proyecto si lo conoces</Form.Label>
          <Form.Control type="text" placeholder="OT o proyecto" className="mb-2" onChange={e => setOt(e.target.value)} />
        </Form.Group>
          <Form.Label className="font-weight-bold">Selecciona el tipo de notificación</Form.Label>
          <ListGroup className="pb-2">
            {tipos.map(item => (
              <ListGroup.Item variant={notificacion === item ? "success" : ""} key={uuid()} onClick={e => setNotificacion(item)}>
                {item}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Form.Group className="mb-0" controlId="descripcion">
          <Form.Label className="font-weight-bold">Escribe una breve descripción detallando lo ocurrido</Form.Label>

          <Form.Control as="textarea" placeholder="Descripción " className="mb-2" onChange={e => setDescripcion(e.target.value)} />
        </Form.Group>
        <Button className="mr-5" variant="success" disabled={!notificacion} onClick={envioNotificacion}>
          Enviar
        </Button>
        <Button variant="danger" onClick={() => props.cancel()}>
          Cancelar
        </Button>
      </Form>
    </Container>
  );
}
