import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getApiOperarios } from "../../modules/APIhelpers";
import { getHorasAcum, getVacaciones } from "../../modules/googleHelpers";
import LoadSpinner from "../LoadSpinner";
import "./admin-page.css";
import { ArrowClockwise, Table } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import { utils, writeFile } from "xlsx";



export default function AdminPage() {
  const [data, setData] = useState(null);
  const [toFixed, setToFixed] = useState(0);

  const setOperariosData = async (_) => {
    const operarioReducer = (idStr, data) =>
      data
        .filter(({ idOpr }) => idOpr === parseInt(idStr))
        .reduce((acum, cur) => {
          return acum + cur.total;
        }, 0);

    const operarios = (await getApiOperarios())
      .filter(
        ({ Baja, IDDepartamento }) =>
          !Baja && IDDepartamento >= "02" && IDDepartamento <= "05"
      )
      .sort((a, b) => a.IDOperario > b.IDOperario);
    const horasAcum = await getHorasAcum();
    const vacaciones = await getVacaciones();

    return operarios.reduce((acc, cur) => {
      const operario = {
        id: cur.IDOperario,
        descripcion: cur.DescOperario,
        horas: operarioReducer(cur.IDOperario, horasAcum),
        vacaciones: operarioReducer(cur.IDOperario, vacaciones),
      };
      operario.dias =
        operario.horas < 0
          ? operario.vacaciones
          : Math.round(operario.horas / 7) + operario.vacaciones;
      acc.push(operario);
      return acc;
    }, []);
  };

  useEffect(() => {
    // Ejecutamos UseEffect al inicio o si se vacía con el botón de refresco
    if (!data) {
      setOperariosData().then((data) => setData(data));
    }
  }, [data]);

  const handleRefresh = () => {
    if (window.confirm("¿Recargar datos?")) setData(null);
  };

  const handleToFixed=({target})=>{
    switch(target.innerText){
      case "+":
        if(toFixed<8) setToFixed(toFixed+1);
        break;
      default:
        if(toFixed>0) setToFixed(toFixed-1);
    }
  }

  const handleExport = () => {
    const tableEl = document.getElementById("admin-data-table");
  
    let wb = utils.table_to_book(tableEl)
    /* Export to file (start a download) */
    writeFile(
      wb,
      `Operarios_${new Date().toISOString().slice(0, 10)}.xlsx`
    );
  };
  if (!data) return <LoadSpinner />;
  return (
    <div className="container admin-container">
      <div className="buttons-container">
        <Table onClick={handleExport}></Table>
        <ArrowClockwise onClick={handleRefresh}></ArrowClockwise>
      </div>
      <table
        className="admin-data table table-striped  table-sm"
        id="admin-data-table"
      >
        <thead>
          <tr>
            <th className="operario-or">Or</th>
            <th className="operario-id">Id</th>
            <th className="operario-desc">Operario</th>
            <th className="operario-data" >
              <Button variant="light" onClick={handleToFixed}>+</Button>
              Horas
              <Button variant="light" onClick={handleToFixed}>-</Button>
              </th>
            <th className="operario-data">Vac.</th>
            <th className="operario-data">Días</th>
          </tr>
        </thead>
        <tbody>
          {data.map(({ id, descripcion, horas, vacaciones, dias }, idx) => (
            <tr key={id} style={horas < 0 ? { color: "red" } : {}}>
              <td className="operario-or">{idx + 1} - </td>
              <td className="operario-id">{id}</td>
              <td className="operario-desc">{descripcion}</td>
              {/* <td className="operario-horas">{getHorasOperario(parseInt(operario.IDOperario)).toLocaleString()}</td> */}
              <td className="operario-data">{horas.toFixed(toFixed)}</td>
              <td className="operario-data">{vacaciones}</td>
              <td className="operario-data">{dias}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
