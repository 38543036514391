import React, { useEffect, useState } from "react";
//import {ThemeProvider} from './context/ThemeContext.jsx'; //Importamos el contexto para la App
import { auth } from "./firebase";
import {
  Spinner,
  Nav,
  Navbar,
  Figure,
  Image,
} from "react-bootstrap";
import RouterApp from "./components/router-app/RouterApp.jsx";
import Login from "./components/Login";

import { getApiOperarios } from "./modules/APIhelpers";

//****************IMAGENES *************/

import logo from "./images/LOGO_CIRCULO.PNG";
import avatar from "./images/avatar.jpg";
import van from "./images/van.jpg";
import rest from "./images/rest.jpg";
import alarm from "./images/panel-control.jpg";
import exit from "./images/exit.jpg";
import summary from "./images/summary.jpg";
import detail from "./images/detail.jpg";

import "./App.css";
import AdminPage from "./components/admin/admin-page";
import Acceso from "./components/acceso/Acceso";

// async function getApiToken(credenciales){
//   try{
//     const response =  await fetchAPI({query: 'ObtenerToken', body: credenciales});
//     return response.access_token;
//   }catch(err){
//     throw err;
//   }

//   }

//async function getApiOperarios(token, idOperario){
//const query = `API/Operarios/${idOperario ? '?IdOperario='+idOperario : ''}`;
//const headers = {Authorization : `Bearer ${token}`};
// const data = await fetchAPI({query, headers});
//Filtramos operarios de alta y no "externos".
//console.log(data);
//return idOperario ? data.find(operario => operario.IDOperario === idOperario) : data;
//}

function App() {
  const [firebaseUser, setFirebaseUser] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [adminView, setAdminView] = useState(false);
  const [showAccessModal, setShowAccessModal] = useState(false);
  //const [operario, setOperario] = useState(false);
  //const [token, setToken] = useState(false);
  //const [user, setUser] = useState(null);

  useEffect(() => {
    if (firebaseUser) {
      auth.currentUser.getIdTokenResult().then(async (token) => {
        //console.log("claims", token.claims.role);
        //console.log(auth.currentUser)
        if (token.claims.role === "admin") {
          // console.log("cargando datos admin");
          const response = await getApiOperarios();
          const data = response.filter(
            ({ IDOperario }) => IDOperario > "000" && IDOperario < "900"
          );
          //console.log(data)
          setAdmin(data);

          // setAdmin(
          //   response.filter((operario) => {
          //     const id = operario.IDOperario;
          //     if (id > "000" && id < "900") return operario;
          //   })
          // );
        } else setAdmin(false);
      });
    }
  }, [firebaseUser]);

  auth.onAuthStateChanged(async (user) => {
    //console.log(user);
    if (user) {
      // console.log('usuario logueado: ', auth.currentUser.email)
      setFirebaseUser(user);

      /* firestore.collection('config').doc('apiCobertec').get()
      .then(async doc => {
        let response = await getApiToken(atob(doc.data().credenciales));
        setToken('response');
        //console.log(user.displayName)
        response = await getApiOperarios(token, user.displayName);
        
        setOperario(response);
      })
      .catch(err => console.log('Error: ', err.message));
      firestore.collection('config').doc('sheetGoogle').get()
      //.then(doc => console.log(doc.data()))
      .catch(err => console.log('Error: ', err.message)); */
    } else {
      setFirebaseUser(null);
    }
  });

  // Preguntamos por false porque con null tenemos que devolver usuario no logueado
  // false es el tiempo de respuesta de Firebase
  let result;

  switch (firebaseUser) {
    case null:
      //console.log('usuario nulo')
      result = <Login />;
      break;
    case false:
      //console.log('usuario falso')
      result = <Spinner animation="border" variant="primary" />;
      break;
    default:
      // result = <RouterApp admin={admin} />;
      result = adminView  && admin? <AdminPage /> : <RouterApp admin={admin} />;
  }
  const NavAdminItem = () => {
    return admin ? (
      <Nav.Item>

      <Nav.Link  href="#" onClick={() => setAdminView(!adminView)}>
        <Image src={adminView ? detail : summary} />
      </Nav.Link>
      </Nav.Item>
    ) : null;
  };

  return (
    <div className="app-container">
      {/* La propiedad expanded se puede utilizar para collapsar programáticamente. Utilizamos collapseOnSelect que parece que solo funciona con Nav.Link */}
      <Navbar
        expand="sm"
        id="app-nav"
        className="app-nav"
        fixed="top"
        onToggle={() => console.log("toogle")}
        collapseOnSelect
      >
        <Navbar.Brand as="div"> 
          <Nav.Item id="logo">
            <img src={logo} alt="Logo Her-jai"/>
          </Nav.Item>
        {firebaseUser && (
          <Nav.Item id="avatar">
            <Figure>
              <Figure.Image src={avatar} />
              <Figure.Caption>{auth.currentUser.displayName}</Figure.Caption>
            </Figure>
          </Nav.Item>
        )}
        </Navbar.Brand>
        {showAccessModal && (
          <Acceso handleClose={() => setShowAccessModal(false)} />
        )}
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="d-flex justify-content-between align-items-center"
            style={{ width: "100%", gap: "10px" }}
            navbarScroll
            // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
          >
            <Nav.Item>
              <Nav.Link
                href="https://docs.google.com/forms/d/e/1FAIpQLSd-tFXX_cj3dKCZ0Sn1ihB-PZ9zkrh0hqeuy857Ow-SCODy3w/viewform "
                target="_blank"
              >
                <Image src={rest} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://checklistherjai.web.app/" target="_blank">
                <Image src={van} />
              </Nav.Link>
            </Nav.Item>

            {firebaseUser && (
              <>
                <Nav.Item>
                  <Nav.Link href="#" onClick={() => setShowAccessModal(true)}>
                    <Image src={alarm} />
                  </Nav.Link>
                </Nav.Item>
                <NavAdminItem />
                <Nav.Item
                 
                >
                  <Nav.Link
                  id="exit"
                   
                  href="#"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Confirma que quieres salir de la aplicación"
                      )
                    )
                   
                      auth.signOut();
                  }}>
                    <Image src={exit} />
                  </Nav.Link>
                </Nav.Item>
              {/*   <Nav.Item
                  as="a"
                  href="#"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Confirma que quieres salir de la aplicación"
                      )
                    )
                      auth.signOut();
                  }}
                >
                  <Figure id="exit">
                    <Image src={exit} />
                  </Figure>
                </Nav.Item> */}
               
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {result}
    </div>
  );
  // return  !fireBaseUser ?(

  //   <div className="d-flex justify-content-center mt-5">

  //     <Login />
  //   </div>
  // ):(
  //   <div className="container mt-1">

  //     <RouterApp/>
  //   </div>
  //   )
}

export default App;
