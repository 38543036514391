import React, { useState } from "react";
import "./acceso.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { auth } from "../../firebase";
import { useRef } from "react";
import { useEffect } from "react";
import { getApiOperarios } from "../../modules/APIhelpers";

export default function Acceso({ handleClose }) {
  const [accesValue, setAccesValue] = useState();
  const inputRef = useRef(null);
  const [disconectTries, setDisconectTries] = useState(0);

  const getAccesValue = async () => {
    const { TarjetaControl } = await getApiOperarios(auth.currentUser.displayName);
    setAccesValue(TarjetaControl);
  };

  useEffect(() => {
    getAccesValue();
  }, []);

  const DisconectButton = () => {
    const buttonText = disconectTries ? "Confirmar" : "Salir de la aplicación";
    const variant = disconectTries ? "warning" : "primary";
    return (
      <Button
        size="sm"
        onClick={() => {
          if (disconectTries) {
            auth.signOut();
            handleClose();
          } else setDisconectTries(disconectTries + 1);
        }}
        variant={variant}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <>
      <Modal
        show={true}
        onClick={({ target }) => {
          // Igual sería más elegante hacerlo con useEffect o con useRef.
          if (target.nodeName === "SPAN" && target.innerText === "×") handleClose();
          if (disconectTries) setDisconectTries(0);
        }}
      >
        <Modal.Header closeButton>
          {!accesValue ? (
            <p>Parece que no tienes un código de acceso para la alarma. Solicítalo en administración</p>
          ) : (
            <Modal.Title>Pulsa para ver tu código de acceso</Modal.Title>
          )}
        </Modal.Header>
        {accesValue && (
          <Modal.Body>
            <div className="access-container">
              <input
                ref={inputRef}
                readOnly
                //className="access-input"
                value={accesValue || ""}
                type="password"
              />

              <AiOutlineEye
                size="2rem"
                onMouseDown={() => inputRef.current.setAttribute("type", "text")}
                onMouseUp={() => inputRef.current.setAttribute("type", "password")}
                onTouchStart={() => inputRef.current.setAttribute("type", "text")}
                onTouchEnd={() => inputRef.current.setAttribute("type", "password")}
              ></AiOutlineEye>
            </div>
          </Modal.Body>
        )}
         {accesValue &&  <Modal.Footer style={{color:'red'}}>

            Esté codigo de acceso es único y para uso personal. No lo apuntes en ningún sitio ni lo compartas con nadie.
        </Modal.Footer>}
      </Modal>
    </>
  );
}
